exports.components = {
  "component---node-modules-gatsby-theme-mate-src-templates-not-found-tsx": () => import("./../../../node_modules/gatsby-theme-mate/src/templates/NotFound.tsx" /* webpackChunkName: "component---node-modules-gatsby-theme-mate-src-templates-not-found-tsx" */),
  "component---src-gatsby-theme-mate-templates-home-js": () => import("./../../../src/gatsby-theme-mate/templates/Home.js" /* webpackChunkName: "component---src-gatsby-theme-mate-templates-home-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-install-js": () => import("./../../../src/pages/install.js" /* webpackChunkName: "component---src-pages-install-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-transaction-terms-js": () => import("./../../../src/pages/transaction-terms.js" /* webpackChunkName: "component---src-pages-transaction-terms-js" */)
}

